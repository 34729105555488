import React, { FC } from 'react';
import { graphql } from 'gatsby';
import InsectsList from 'containers/InsectsList';

import { Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Banner from 'components/Banner';
import { BANNER } from 'components/Banner/models.d';

const InsectipediaPage: FC<{ data: InsectipediaPageTypes.InsectipediaPageType }> = ({
  data: { allInsectDetails, insectipedia },
}) => {
  const { nodes: insectDetails } = allInsectDetails;
  const { seo, urls, banner, pageName, introductionText } = insectipedia;
  const { title, keywords, description } = seo;

  const orderedInsects = insectDetails.sort((a, b) => a.orderNumber - b.orderNumber);

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo, pageName }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner banner={banner} variant={BANNER.VARIANT.PRIMARY} />
      <InsectsList introductionText={introductionText} insects={orderedInsects} />
    </Layout>
  );
};

export const query = graphql`
  query InsectipediaPageQuery($lang: String) {
    insectipedia(lang: { eq: $lang }) {
      pageName
      seo {
        ...SeoFragment
      }
      urls {
        href
        lang
      }
      banner {
        ...PageBannerFragment
      }
      introductionText
    }
    allInsectDetails {
      nodes {
        ...InsectDetailFragment
      }
    }
  }
`;

export default InsectipediaPage;
