import React, { FC } from 'react';

import { Container } from 'layout';
import InsectCard from 'components/InsectCard';
import PageIntro from 'components/PageIntro';

import { InsectsListProps } from './models';

import './InsectsList.scss';

const InsectsList: FC<InsectsListProps> = ({ introductionText, insects }) => {
  const insectsListRender = insects.map(({ insectImage, tags, pageName, button }) => (
    <InsectCard
      image={insectImage}
      insectCategory={tags[0].name}
      insectName={pageName}
      button={button}
      key={pageName}
    />
  ));

  return (
    <Container element="section" className="insects-list" fluid noPadding>
      <PageIntro text={introductionText} />
      <Container className="insects-list__wrapper">{insectsListRender}</Container>
    </Container>
  );
};

export default InsectsList;
