import React, { FC } from 'react';
import { Link } from '@reach/router';

import Image from 'common/Image';
import Typography from 'common/Typography';
import Button, { BUTTON } from 'components/Button';

import { InsectCardProps } from './models';

import './InsectCard.scss';

const InsectCard: FC<InsectCardProps> = ({ image, insectCategory, insectName, button }) => {
  const {
    buttonText,
    buttonLink: { url },
    ariaLabel,
  } = button;

  const renderButton = () => {
    if (!button) return null;

    return (
      <Button
        variant={BUTTON.VARIANT.CONTAINED}
        customClass="insect-card__button"
        icon
        ariaLabel={ariaLabel}
        link={url}
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <div className="insect-card" data-testid="insect-card">
      <div className="insect-card__image">
        {image ? (
          <Link to={url}>
            {' '}
            <Image imageData={image} alt={image.altText} className="insect-card__photo" />
          </Link>
        ) : null}
      </div>
      <div className="insect-card__info">
        <div>
          <Typography variant="label" customClass="insect-card__category" weight="regular">
            {insectCategory}
          </Typography>
          <Link to={url}>
            <Typography variant="heading8" customClass="insect-card__name" weight="bold">
              {insectName}
            </Typography>
          </Link>
        </div>
        {renderButton()}
      </div>
    </div>
  );
};

export default InsectCard;
